import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  background: #ffffff;
  position: relative;

  .p-section-inner {
    display: flex;
    max-width: 1200px;
    padding: 150px 0 80px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    .p-section-feature {
      width: 100%;
      display: flex;
      margin: 0 auto 60px;
      justify-content: space-around;

      .p-feature-01,
      .p-feature-02,
      .p-feature-03 {
        width: calc(100% / 3.3);
        position: relative;
        text-align: center;

        h4 {
          width: 100%;
          max-width: 240px;
          color: #ff9602;
          background-image: url(/images/official/top/bgFeature01.png);
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: bottom right;
          margin: 0 auto 20px;
        }

        .img-wrap {
          width: 100%;
          max-width: 280px;
          height: auto;
          margin-bottom: 20px;
        }

        p {
        }
      }
    }

    button {
      display: flex;
      margin: 0 auto;
    }
  }

  .award-wrap {
    padding: 100px 0 0;
    margin-bottom: -80px;
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      padding: 360px 0 80px 0;
    }

    .award-wrap {
      padding: 340px 0 0;
      margin-bottom: -320px;
    }
  }

  ${BreakPoints.xLarge} {
    .p-section-inner {
      padding: 240px 0 80px 0;

      .p-section-feature {
        .p-feature-01,
        .p-feature-02,
        .p-feature-03 {
          .img-wrap {
          }

          > p {
            top: 12%;
            font-size: 1.25em;
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 270px 0 60px;

      .p-section-feature {
        display: block;

        .p-feature-01,
        .p-feature-02,
        .p-feature-03 {
          align-items: center;
          width: 90%;
          margin: 0 auto;

          .img-wrap {
            width: 30%;
          }

          > p {
            top: unset;
            position: relative;
            width: 100%;
            text-align: center;
            padding: 0 0 30px 0;

            br {
              display: none;
            }
          }

          &:last-child {
            > p {
              padding: 0;
            }
          }
        }
      }
    }

    .award-wrap {
      padding: 220px 0 0;
      margin-bottom: -180px;
    }
  }
`

/**
 * サービスサイトTOPセクション01
 * @constructor
 */
const OfficialTopSection01 = () => {
  const routeParam = useRouteParam('/feature')
  return (
    <Wrapper>
      {/** 今後受賞が増えたらここを使う
             <AwardBnrArea className="award-wrap" />
             */}
      <div className="p-section-inner anim-fade-up">
        <OfficialCommonH3Set label="特徴" />
        <div className="p-section-feature">
          <div className="p-feature-01">
            <h4 className="txt-size-26 txt-bold">
              すぐに導入、
              <br />
              利用開始できる！
            </h4>
            <img
              src="/images/official/feature/imgTopFeature01.png"
              className="img-wrap"
              alt="すぐに導入、利用開始できる！"
            />
            <p className="txt-size-22 txt-bold">
              初期費用ゼロ
              <br />
              専用機器も不要
            </p>
          </div>
          <div className="p-feature-02">
            <h4 className="txt-size-26 txt-bold">
              各種情報
              <br />
              リアルタイム把握
            </h4>
            <img
              src="/images/official/feature/imgTopFeature02.png"
              className="img-wrap"
              alt="各種情報リアルタイム把握"
            />
            <p className="txt-size-22 txt-bold">
              勤務状況の把握や
              <br />
              ステータス確認
            </p>
          </div>
          <div className="p-feature-03">
            <h4 className="txt-size-26 txt-bold">
              細かい
              <br />
              承認フロー設定
            </h4>
            <img
              src="/images/official/feature/imgTopFeature03.png"
              className="img-wrap"
              alt="細かい承認フロー設定"
            />
            <p className="txt-size-22 txt-bold">
              最大５段階を
              <br />
              個人レベルで設定可能
            </p>
          </div>
        </div>
        <OfficialCommonBtn
          size="mega"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          特徴をもっと見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialTopSection01
