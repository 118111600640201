import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import AnimLoading from '../components/atoms/animLoading'
import MovieBtn from '../components/atoms/movieBtn'
import MovieContent from '../components/atoms/movieContent'
import SEO from '../components/atoms/seo'
import OfficialGalleryBtnSet from '../components/molecules/officialGalleryBtnSet'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialTopMain from '../components/organisms/officialTopMain'
import OfficialTopSection01 from '../components/organisms/officialTopSection01'
import OfficialTopSection02 from '../components/organisms/officialTopSection02'
import OfficialTopSection03 from '../components/organisms/officialTopSection03'

const Wrapper = styled.main`
  position: relative;
  .bg {
    display: block;
    width: 100vw;
    height: auto;
    position: fixed;
    top: 60px;
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>
/**
 * Indexページ【サービスサイトTOP】
 * @constructor
 */
const IndexPage = ({ location }: IProps) => (
  <>
    <OfficialLayout location={location}>
      <SEO />
      <Helmet>
        <title>キンクラ - 客先常駐特化型のクラウド勤怠管理システム</title>
        <script>
          {`
        document.querySelector('video').playbackRate = 0.80;
        document.querySelector('video').currentTime = 29
        `}
        </script>
      </Helmet>
      <Wrapper>
        <img
          src="/images/official/common/bgPublic01.png"
          alt="キンクラ"
          className="bg"
        />
        <OfficialTopMain />
        <OfficialTopSection01 />
        <OfficialTopSection02 />
        <OfficialGalleryBtnSet />
        <OfficialTopSection03 />
      </Wrapper>
      <AnimLoading />
    </OfficialLayout>
    <MovieBtn />
    <MovieContent />
  </>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
