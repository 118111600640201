import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 30px;
  z-index: 1;
  a {
    background: #f785a8;
    display: flex;
    width: 290px;
    height: 60px;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    font-weight: 600;
    color: #ffffff;
    border-radius: 20px 20px 0 0;
    border: solid 4px #ffc7df;
    border-bottom: none;
    font-size: 1.2em;
    img {
      width: 28px;
      height: 21px;
      margin: 0 6px 1px 0;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
}

/**
 * 動画フル版表示用ボタン
 */
const MovieBtn = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      <a href="#movie-full">
        <img
          src="/images/official/top/iconMovie01.png"
          alt="キンクラの紹介動画を見る"
        />
        キンクラの紹介動画を見る
      </a>
    </Wrapper>
  )
}
export default MovieBtn
