import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFunctionItems from '../molecules/officialFunctionItems'
import OfficialNewFunctionItem from './officialNewFunctionItem'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  background: rgba(233, 245, 241, 0.6);
  position: relative;

  .p-section-section-label {
    padding: 80px 0 0;
  }

  .p-section-inner {
    max-width: 1080px;
    padding: 20px 0 80px;
    margin: 0 auto;

    .p-section-function {
      padding: 30px 10px 0;
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    button {
      display: flex;
      margin: 0 auto;
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-function {
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0;

      .p-section-function {
        padding: 10px 10px 0;
      }
    }
  }
`

/**
 * サービスサイトTOPセクション03
 * 機能
 * @constructor
 */
const OfficialTopSection03 = () => {
  const routeParam = useRouteParam('/function')
  return (
    <Wrapper>
      <OfficialCommonH3Set
        label="機能一覧"
        className="anim-fade-up p-section-section-label"
      />
      <OfficialNewFunctionItem />
      <div className="p-section-inner">
        <div className="p-section-function anim-fade-up">
          <OfficialFunctionItems
            label="打刻"
            description="PC・タブレット・スマホから簡単に打刻ができます"
          >
            <img
              src="/images/official/top/imgFunction01.png"
              alt="キンクラの打刻"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="勤怠管理"
            description="勤怠申請の提出から承認も集計も一元管理"
          >
            <img
              src="/images/official/top/imgFunction02.png"
              alt="キンクラの勤怠管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="シフト管理"
            description="作成から提出まで数クリックで完結する簡単設計"
          >
            <img
              src="/images/official/top/imgFunction03.png"
              alt="キンクラのシフト管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="給与ソフト連携"
            description="給与ソフトに合わせてエクスポートデータを生成"
          >
            <img
              src="/images/official/top/imgFunction04.png"
              alt="キンクラの給与ソフト連携"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="アラート機能"
            description={'出退勤や残業集計などなど\nアラートを通知します'}
          >
            <img
              src="/images/official/top/imgFunction05.png"
              alt="キンクラのアラート機能"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="経費精算"
            description="電子帳簿保存法対応！キンクラで簡単清算"
          >
            <img
              src="/images/official/top/imgFunction06.png"
              alt="キンクラの経費精算"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="36協定"
            description="各企業で定めている労働時間を設定できます"
          >
            <img
              src="/images/official/top/imgFunction07.png"
              alt="キンクラの36協定"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="CSV出力"
            description="勤務表や各種申請などのデータ出力が可能です"
          >
            <img
              src="/images/official/top/imgFunction08.png"
              alt="キンクラのCSV出力"
            />
          </OfficialFunctionItems>
        </div>
        <OfficialCommonBtn
          size="mega"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          機能一覧をもっと見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialTopSection03
