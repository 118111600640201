import styled from '@emotion/styled'
import React, { useRef } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  &.modal-wrapper {
    .modal-window {
      width: 90vw;
      max-width: 880px;
      .modal-content {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  ${BreakPoints.medium} {
    &.modal-wrapper {
      .modal-window {
        .modal-content {
        }
      }
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
}

/**
 * 動画フル版表示用コンテンツ
 */
const MovieContent = (props: Props): JSX.Element => {
  const { className = '' } = props
  const youtubeRef = useRef<HTMLIFrameElement>(null)
  const onStop = () => {
    youtubeRef.current?.contentWindow?.postMessage(
      '{"event":"command","func":"pauseVideo","args":""}',
      '*'
    )
  }

  return (
    <Wrapper className={`modal-wrapper${className}`} id="movie-full">
      <div className="modal-overlay" />
      <div className="modal-window">
        <div className="modal-content">
          <iframe
            ref={youtubeRef}
            width="828"
            height="466"
            src="https://www.youtube.com/embed/LYobNX9P-BU?enablejsapi=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <a href="#!" className="modal-close" onClick={onStop}>
          ×
        </a>
      </div>
    </Wrapper>
  )
}
export default MovieContent
