import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import UpdateInfoBnrV3 from '../atoms/updateInfoBnrV3'
import UpdateInfoContentV3 from '../atoms/updateInfoContentV3'
import AwardBadgeArea from '../molecules/awardBadgeArea'
import UpdateInfoBnrV4 from '../atoms/updateInfoBnrV4'
import UpdateInfoContentV4 from '../atoms/updateInfoContentV4'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%2C%20.cls-3%20%7B%20fill%3A%20none%3B%20%7D%20.cls-1%20%7B%20stroke%3A%20%230000000a%3B%20%7D%20.cls-2%20%7B%20stroke%3A%20none%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_23613%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2023613%22%20class%3D%22cls-1%22%3E%20%3Crect%20class%3D%22cls-2%22%20width%3D%2212%22%20height%3D%2212%22%2F%3E%20%3Crect%20class%3D%22cls-3%22%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2211%22%20height%3D%2211%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: repeat;
  border-bottom: solid 4px #313a38;
  z-index: 1;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 20%;
    border-bottom: solid 4px #bbc0c6;
    bottom: -16px;
  }

  &:after {
    right: 0;
  }

  .p-main-inner {
    display: flex;
    max-width: 1500px;
    padding: 80px 0;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .p-inner-txt {
      padding: 0 10px;

      h2 {
        font-weight: 800;
        font-size: 3.75em;
        line-height: 1.2em;
        text-align: center;
        margin: 0 auto 40px;

        span {
          color: #0b6f7a;
        }
      }

      h3 {
        margin: 0 auto 60px;
        font-size: 1.625em;
        font-weight: bold;
      }

      button {
        display: flex;
        margin: 0 auto 20px;
      }

      .p-inner-ribbon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0 0 0;
        margin: 20px auto 10px;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22522.004%22%20height%3D%2288.367%22%20viewBox%3D%220%200%20522.004%2088.367%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%233EC7B3%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_2240%22%20data-name%3D%22%E3%83%91%E3%82%B9%202240%22%20class%3D%22cls-1%22%20d%3D%22M687.13%2C549.26c-.1-.106-10.287-10.71-14.332-24.91a96.34%2C96.34%2C0%2C0%2C1-3.3-26.006%2C1.383%2C1.383%2C0%2C0%2C0-1.189-1.512H647.051L643.02%2C478.28a1.3%2C1.3%2C0%2C0%2C0-1.205-1.1H216.928l-3.194-12.708a1.366%2C1.366%2C0%2C0%2C0-.838-.981%2C1%2C1%2C0%2C0%2C0-.358-.056H166.787a1.367%2C1.367%2C0%2C0%2C0-1.24%2C1.464%2C1.574%2C1.574%2C0%2C0%2C0%2C.415%2C1.079c.1.1%2C10.287%2C10.709%2C14.332%2C24.908a96.389%2C96.389%2C0%2C0%2C1%2C3.3%2C26.006%2C1.382%2C1.382%2C0%2C0%2C0%2C1.194%2C1.512l.049%2C0h21.212l4.031%2C18.549a1.3%2C1.3%2C0%2C0%2C0%2C1.207%2C1.1H636.166l3.194%2C12.707a1.366%2C1.366%2C0%2C0%2C0%2C.838.98%2C1.06%2C1.06%2C0%2C0%2C0%2C.356.062h45.753a1.365%2C1.365%2C0%2C0%2C0%2C1.243-1.458%2C1.571%2C1.571%2C0%2C0%2C0-.422-1.092Zm-472.8-72.072H202.078l9.808-9.737Zm424.427%2C60.874h12.257l-9.808%2C9.737Z%22%20transform%3D%22translate(-165.547%20-463.439)%22%2F%3E%3C%2Fsvg%3E');
        width: 522px;
        height: 88px;
        color: #ffffff;
        font-weight: 600;
        font-size: 2.125em;
        filter: drop-shadow(0 2px 2px rgb(0 0 0 / 30%));
      }
    }

    .p-inner-movie {
      position: relative;
      box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
      background: #ffffff;
      padding: 10px;
      border-radius: 3px;
      width: 52%;
      min-width: 320px;
      height: fit-content;
      margin: 0 auto;
    }

    .bnr-update {
      position: absolute;
      bottom: -105px;
      z-index: 1;
      width: 97vw;
      max-width: 1500px;
      display: flex;
      justify-content: center;
    }
  }

  .div-modal {
    &.modal-wrapper {
      .modal-window {
        padding-top: 0;

        .modal-content {
          display: flex;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            margin: 54px 0 0;
            border: solid 2px #cccccc;
          }
        }
      }
    }
  }

  ${BreakPoints.xxLarge} {
    ${BreakPoints.xxLarge} {
      overflow: visible;

      .p-main-inner {
        justify-content: center;
        padding-top: 120px;

        .p-inner-txt {
          display: contents;

          h2 {
            position: absolute;
            top: 10px;
            font-size: 2.75em;

            br {
              display: none;
            }
          }

          h3 {
            position: absolute;
            top: 70px;
            font-size: 1.425em;
          }

          button {
            position: absolute;
            bottom: 10px;
          }

          .p-inner-ribbon {
            position: absolute;
            bottom: -70px;
            z-index: 1;
          }
        }

        .bnr-update {
          right: unset;
          bottom: -300px;
        }
      }

      .award-position {
        //position: unset;
        //left: unset;
        z-index: 1;
      }
    }
  }

  ${BreakPoints.xLarge} {
    .p-main-inner {
      .bnr-update {
        bottom: -220px;
      }
    }
  }

  ${BreakPoints.large} {
    .p-main-inner {
      padding-top: 110px;

      .p-inner-txt {
        h2 {
          top: 15px;
          font-size: 2.45em;
        }

        h3 {
          font-size: 1.25em;
        }

        button {
          bottom: 5px;
        }

        .p-inner-ribbon {
          transform-origin: top center;
          transform: scale(0.7);
          bottom: -80px;
        }
      }

      .bnr-update {
        bottom: -180px;
      }
    }
  }

  ${BreakPoints.medium} {
    .p-main-inner {
      padding-top: 170px;

      .p-inner-txt {
        h2 {
          br {
            display: block;
          }
        }

        h3 {
          top: 122px;
        }
      }

      .bnr-update {
        margin: 0 auto;
        right: unset;
        top: 125%;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        align-items: center;
      }
    }
  }

  ${BreakPoints.small} {
    .p-main-inner {
      .p-inner-txt {
        h3 {
          top: 112px;
          white-space: pre-wrap;
          text-align: center;
          font-size: 1em;
        }
      }
    }
  }
`

/**
 * サービスサイトTOPメインエリア
 * @constructor
 */
const OfficialTopMain = () => {
  const routeParam1 = useRouteParam('https://sign-up.kintaicloud.jp/sign-up')
  return (
    <>
      <Wrapper>
        <div className="p-main-inner">
          <div className="p-inner-txt">
            <h2>
              多彩な勤務形態に
              <br />
              <span>ピタッ</span>とハマる
            </h2>
            <h3>{'誰でも簡単に使える\nクラウド勤怠管理システム'}</h3>
            <OfficialCommonBtn
              size="mega"
              level="strong"
              className="award-position"
              onClick={() => window.open(routeParam1)}
            >
              無料トライアルはこちら
            </OfficialCommonBtn>
            <div className="p-inner-ribbon">翌月末まで無料</div>
          </div>
          <div className="p-inner-movie">
            <video loop autoPlay muted playsInline width="100%" height="100%">
              <source id="video" src="/movies/movieShort.mp4#t=[27]" />
            </video>
            <AwardBadgeArea />
          </div>
          <div className="bnr-update">
            <UpdateInfoBnrV4 idName="#modal-bnr03" />
            <UpdateInfoBnrV3 idName="#modal-bnr02" />
            {/* <UpdateInfoBnr idName="#modal-bnr01" /> */}
          </div>
        </div>
      </Wrapper>
      <UpdateInfoContentV4 idName="modal-bnr03" />
      <UpdateInfoContentV3 idName="modal-bnr02" />
      {/* <UpdateInfoContent idName="modal-bnr01" /> */}
    </>
  )
}

export default OfficialTopMain
