import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  position: absolute;
  width: 120px;
  top: -60px;
  right: -50px;

  .img-wrap {
    width: 100%;
  }

  .img-wrap2 {
    width: 140%;
  }

  @media screen and (max-width: 1420px) {
    right: 0;
  }

  ${BreakPoints.xxLarge} {
    width: 90px;
    top: -20px;
    right: -40px;
  }

  ${BreakPoints.xLarge} {
    width: 80px;
    top: -10px;
    right: -50px;
  }

  ${BreakPoints.large} {
    top: -15px;
  }

  ${BreakPoints.medium} {
    width: 80px;
    top: -10px;
    right: -40px;
  }
`
type Props = {
  /** クラス名 */
  className?: string
}

/**
 * アワードバッジ／アワードリボン
 * @constructor
 */
const AwardBadgeArea = (props: Props) => {
  const { className = '' } = props
  return (
    <Wrapper className={className}>
      <img
        src="/images/logo_jiima_circle.png"
        className="img-wrap2"
        alt="JIIMA認証"
        style={{ position: 'relative', left: '-20%' }}
      />
      <img
        src="/images/official/award/smartus2023spring.png"
        className="img-wrap"
        alt="スマータスアワード2023"
      />
    </Wrapper>
  )
}

export default AwardBadgeArea
