import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  background: rgba(249, 255, 244, 0.6);
  border-right: solid 100px #ffffff;
  border-left: solid 100px #ffffff;
  .p-section-inner {
    display: flex;
    max-width: 1200px;
    padding: 80px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    .p-section-feature {
      width: 100%;
      display: flex;
      margin: 0 auto 60px;
      .p-feature-01,
      .p-feature-02,
      .p-feature-03 {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        .img-wrap {
          width: 100%;
          max-width: 330px !important;
          height: 100%;
        }
        > p {
          position: absolute;
          height: 75px;
          display: flex;
          align-items: center;
          top: 45px;
          font-weight: 600;
          font-size: 1.625em;
          line-height: 1.2em;
          text-align: center;
          color: #00ac93;
        }
      }
    }
    button {
      display: flex;
      margin: 0 auto;
    }
  }
  ${BreakPoints.xLarge} {
    border: none;
    .p-section-inner {
      .p-section-feature {
        .p-feature-01,
        .p-feature-02,
        .p-feature-03 {
          .img-wrap {
          }
          > p {
            top: 12%;
            font-size: 1.25em;
          }
        }
      }
    }
  }
  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 80px 0 60px;
      .p-section-feature {
        display: block;
        .p-feature-01,
        .p-feature-02,
        .p-feature-03 {
          align-items: center;
          width: 90%;
          margin: 0 auto;
          .img-wrap {
            width: 30%;
          }
          > p {
            top: unset;
            position: relative;
            width: 70%;
            text-align: left;
            padding: 0 0 0 20px;
            br {
              display: none;
            }
          }
        }
      }
    }
  }
`

/**
 * サービスサイトTOPセクション02
 * 導入効果
 * @constructor
 */
const OfficialTopSection02 = () => {
  const routeParam = useRouteParam('/benefit')
  return (
    <Wrapper>
      <div className="p-section-inner">
        <div className="p-section-feature">
          <div className="p-feature-01 anim-fade-up">
            <img
              className="img-wrap"
              src="/images/official/top/imgFeature01.png"
              alt="キンクラの強み1"
            />
            <p>
              SESに最適化された
              <br />
              勤怠システム
            </p>
          </div>
          <div className="p-feature-02 anim-fade-up">
            <img
              className="img-wrap"
              src="/images/official/top/imgFeature02.png"
              alt="キンクラの強み2"
            />
            <p>
              自由度が高く
              <br />
              簡単なシフト作成
            </p>
          </div>
          <div className="p-feature-03 anim-fade-up">
            <img
              className="img-wrap"
              src="/images/official/top/imgFeature03.png"
              alt="キンクラの強み3"
            />
            <p>
              細かく行き届いた
              <br />
              管理アラートの通知
            </p>
          </div>
        </div>
        <OfficialCommonBtn
          size="mega"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          導入効果をもっと見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialTopSection02
