import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: calc(100% / 3);
  height: 300px;
  padding: 25px 40px;
  margin: 0 0 50px;
  text-align: center;
  letter-spacing: -0.03em;
  border-right: solid 1px #dcdee1;
  &:nth-of-type(3n),
  &:last-child {
    border: none;
  }
  .name-txt {
    margin: 0 auto 40px;
    font-weight: 600;
    font-size: 1.625em;
    color: #ff9600;
  }
  &:last-child {
    margin-bottom: 40px;
  }
  img {
    display: inline-block;
    width: 130px;
    height: 80px;
    margin-bottom: 30px;
  }
  .description {
    width: 100%;
    white-space: pre-wrap;
  }
  ${BreakPoints.large} {
    padding: 20px;
  }
  ${BreakPoints.medium} {
    width: calc(100vw - 80px);
    display: block;
    overflow: hidden;
    border-right: none;
    border-bottom: solid 1px #dcdee1;
    padding: 20px 0;
    margin: 0;
    height: auto;
    &:nth-of-type(3n) {
      border-bottom: solid 1px #dcdee1;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .name-txt {
      margin-bottom: 20px;
    }
    img {
      float: left;
      margin-bottom: 0;
    }
    .description {
      padding: 0 20px 0 150px;
      text-align: left;
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** タイトル */
  label: string
  /** 内容（画像） */
  children: ReactNode
  /** 補足テキスト */
  description: string
}

/**
 * 機能1つ分コンポーネント
 * @param props
 * @constructor
 */
const OfficialFunctionItems = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`function-items ${className}`}>
      <div className="name-txt">{props.label}</div>
      {props.children}
      <p className="description">{props.description}</p>
    </Wrapper>
  )
}

export default OfficialFunctionItems
